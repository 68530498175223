import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearAccount, clearSearch, searchByAdvisor } from '../../features/client/client.slice';
import { getCodes } from '../../features/advisor/advisor.slice';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Paper,
  Typography
} from '@mui/material';
import PrimaryButton from '../ui/buttons/primaryButton';
import CircularProgress from '../ui/controls/circularProgress';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Form from '../ui/forms';
import Input from '../ui/forms/fields/input';
import Select from '../ui/forms/fields/select';
import { Box, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTitle } from '../../hooks/titleProvider';

function AdvisorClients() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const { setTitle } = useTitle();

  const [noCodesWarning, setNoCodesWarning] = useState(false);
  const [disProviders, setDisProviders] = useState([]);
  const searching = useSelector((state) => state.client.searching);
  const results = useSelector((state) => state.client.results);

  const codes = useSelector((state) => state.advisor.codes);

  const schema = yup.object().shape({
    serviceProvider: yup.string().required('Service Provider is required'),
    term: yup.string()
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    setTitle(`My Clients`);
  }, []);

  useEffect(() => {
    dispatch(clearAccount());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCodes());

    // return () => {
    //   dispatch(clearSearch());
    // };
  }, [dispatch]);

  useEffect(() => {
    if (codes && codes.length === 0) {
      setNoCodesWarning(true);
    } else {
      setNoCodesWarning(false);
      //Distinct Providers from Codes
      const providers = codes ? codes.map((code) => code.provider) : [];

      //This does not work to get distinct providers on _id using providers.filter
      const disProviders = providers.filter(
        (provider, index, self) =>
          index === self.findIndex((p) => p._id === provider._id && provider.mode === 'active')
      );

      setDisProviders(disProviders);
    }
  }, [codes]);

  const onSubmit = (data) => {
    dispatch(searchByAdvisor(data));
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
          border: 'none',
          boxShadow: 'none'
        }}>
        <Form>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent="space-between"
            alignItems="center">
            <Select
              label="Service Provider"
              control={control}
              name="serviceProvider"
              error={errors.serviceProvider}>
              <MenuItem value={'all'}>All</MenuItem>
              {disProviders.length > 0 &&
                disProviders.map((provider) => (
                  <MenuItem key={provider._id} value={provider._id}>
                    {provider.name}
                  </MenuItem>
                ))}
            </Select>
            <Input
              label="Search  (Name, ID Number / Registration Number, Account Number)"
              {...register('term')}
              error={errors.term}
            />
            <Box display={'flex'} gap={1}>
              <Button
                variant={'text'}
                onClick={() => dispatch(clearSearch())}
                sx={{
                  my: 2,
                  px: 3,
                  py: 1,
                  borderRadius: '10px',
                  textTransform: 'capitalize'
                }}>
                Clear Search
              </Button>
              <Button
                variant={'contained'}
                color="accent"
                onClick={() => handleSubmit(onSubmit)()}
                sx={{
                  my: 2,
                  px: 3,
                  py: 1,
                  borderRadius: '10px',
                  color: 'text.light',
                  textTransform: 'capitalize'
                }}>
                Search
              </Button>
            </Box>
          </Box>
        </Form>
      </Paper>

      {searching ? (
        <CircularProgress caption="Searching" />
      ) : results && results.length > 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography m={2} variant="h6">
            {results.length} Search Results
          </Typography>

          {results &&
            results.map((client) => {
              let clientName = '';

              if (
                client.entityName !== null &&
                client.entityName !== '' &&
                client.entityName !== undefined
              ) {
                if (
                  client.firstName !== null ||
                  client.firstName !== '' ||
                  client.firstName !== undefined
                ) {
                  clientName = `${client.entityName} (${client.firstName} ${client.lastName})`;
                } else {
                  clientName = `${client.entityName}`;
                }
              } else {
                clientName = `${client.firstName} ${client.lastName}`;
              }

              return (
                <Card
                  raised={false}
                  sx={{
                    margin: 1,
                    width: '100%',
                    maxWidth: '700px',
                    background: `${theme.palette.background.paper} !important`,
                    boxShadow: 'none !important',
                    border: 'none !important',
                    borderRadius: '4px !important'
                  }}>
                  <CardHeader
                    title={`${clientName}`}
                    subheader={`${client.clientId}`}
                    sx={{
                      '.MuiCardHeader-title': {
                        fontSize: '0.7rem'
                      },
                      '.MuiCardHeader-subheader': {
                        fontSize: '0.6rem',
                        fontWeight: 'bold'
                      }
                    }}
                  />
                  <CardContent>
                    <Box>
                      {client.accounts &&
                        client.accounts.map((account) => (
                          <Box key={account} display={'flex'} flexDirection={'column'}>
                            <Typography variant="body2" fontSize={'10px'}>
                              {account}
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </CardContent>
                  <CardActions
                    disableSpacing
                    sx={{ width: '100%', justifyContent: 'center', padding: '0' }}>
                    <PrimaryButton onClick={() => navigate(`/advisor/clients/${client._id}`)}>
                      View
                    </PrimaryButton>
                  </CardActions>
                </Card>
              );
            })}
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          m={3}>
          <Typography variant="h6">No Results Found</Typography>
        </Box>
      )}
    </Box>
  );
}

export default AdvisorClients;
