import React, { useCallback, useEffect } from 'react';
import { useNavigation, useNavigationType, useParams } from 'react-router-dom';
import {
  clearAccount,
  getBalances,
  getById,
  getDaily
} from '../../../features/client/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useBanner } from '../../../hooks/bannerProvider';
import { useTitle } from '../../../hooks/titleProvider';
import { Box } from '@mui/system';
import Overview from '../../account/balancesView/components/overview';
import Products from '../../account/balancesView/components/products';
// import DailyAccount from '../../account/balancesView/components/dailyAccount';
import OrangeCircularProgress from '../../ui/controls/circularProgress';
import { AccessMap, UserFunctions, hasAccess } from '../../../helpers/access';
import ClientUpdate from './clientUpdate';
import { Typography } from '@mui/material';
import ClientReports from './clientReports';

const ClientAccounts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const client = useSelector((state) => state.client.client);
  const account = useSelector((state) => state.client.account);
  const loadingBalances = useSelector((state) => state.client.loadingBalances);
  const navigationType = useNavigationType();

  const { setTitle } = useTitle();
  const { setBanner } = useBanner();

  useEffect(() => {
    setBanner(null);
  }, [setBanner]);

  useEffect(() => {
    if (!client) {
      dispatch(getById(id));
    }

    return () => {
      setTitle('');
    };
  }, [id, dispatch, navigationType]);

  const memoizedSetTitle = useCallback(
    (title) => {
      setTitle(title);
    },
    [setTitle]
  );

  useEffect(() => {
    if (client) {
      if (client.firstName || client.entityName) {
        memoizedSetTitle(
          (client.firstName ? client.firstName : client.entityName || '') +
            ' ' +
            (client.lastName ?? '') || ''
        );
      } else if (client.idNumber) {
        memoizedSetTitle(client.idNumber);
      } else {
        memoizedSetTitle('Client');
      }
    }
  }, [client, memoizedSetTitle]);

  useEffect(() => {
    if (client) {
      dispatch(getBalances(client._id));
    }

    return () => {};
  }, [client, dispatch, id]);

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      {!loadingBalances && account ? (
        <Box width={'100%'} maxWidth={'700px'}>
          {/* <Overview account={account} title={`Fairtree`} /> */}
          <Box display="flex" justifyContent={'flex-end'} alignSelf={'flex-end'} gap={2}>
            {hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Update) ? (
              <ClientUpdate clientId={client.idNumber} />
            ) : null}

            {hasAccess(user, UserFunctions.ClientsAdmin, AccessMap.Read) ? (
              <ClientReports client={client} />
            ) : null}
          </Box>

          <Products account={account} admin={true} />
        </Box>
      ) : (
        <OrangeCircularProgress caption="Fetching your profile summary." />
      )}
    </Box>
  );
};

export default ClientAccounts;
