import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuItem,
  Badge,
  IconButton,
  Typography,
  Paper,
  Box,
  Avatar,
  Stack
} from '@mui/material';
import { styled } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationIcon from '@mui/icons-material/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../features/auth/auth.slice';
import {
  markNotificationAsSeen,
  getUnseenCount,
  dismissNotification,
  getNotifications
} from '../../../features/notification/notification.slice';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

const settings = [{ title: 'My Profile', link: '/user/profile' }, { title: 'Sign out' }];

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  maxWidth: 500,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%' // Ensure the width is consistent
}));

const ProfileMenu = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElMore, setAnchorElMore] = useState({});
  const [expandedIndex, setExpandedIndex] = useState(null);

  const notifications = useSelector((state) => state.notification.notifications || []);
  const unseenCount = useSelector((state) => state.notification.unseenCount);
  const userId = useSelector((state) => state.auth.user._id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getNotifications()).unwrap().then(dispatch(getUnseenCount()));
  }, [dispatch]);

  const handleOpenMenu = (setAnchorEl) => (event) => {
    setAnchorEl(event.currentTarget);

    if (setAnchorEl === setAnchorElNotifications) {
      const hasUnseenNotifications = notifications.some((notification) => !notification.seen);
      if (hasUnseenNotifications) {
        markAsSeen();
      }
    }
  };

  const markAsSeen = () => {
    notifications
      .filter((notification) => !notification.seen)
      .forEach((notification) => {
        dispatch(
          markNotificationAsSeen({ notificationId: notification._id, userId: userId, seen: true })
        )
          .unwrap()
          .then(dispatch(getUnseenCount()));
      });
  };

  const handleCloseMenus = () => {
    setAnchorElUser(null);
    setAnchorElNotifications(null);
    setAnchorElMore({});
  };

  const handleNavigateOrLogout = (setting) => {
    if (setting.title === 'Sign out') {
      dispatch(logout());
    } else {
      navigate(setting.link);
    }
    handleCloseMenus();
  };

  const handleOpenMoreMenu = (event, index) => {
    setAnchorElMore({ ...anchorElMore, [index]: event.currentTarget });
  };

  const handleHideNotification = (notificationId) => {
    dispatch(dismissNotification({ notificationId, userId }))
      .unwrap()
      .then(dispatch(getNotifications()));
    setAnchorElMore({});
  };

  const handleToggleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenMenu(setAnchorElUser)} sx={{ p: 1 }}>
        <PersonIcon />
      </IconButton>
      <IconButton onClick={handleOpenMenu(setAnchorElNotifications)} sx={{ p: 1 }}>
        <Badge badgeContent={unseenCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        id="profile-menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseMenus}>
        {settings.map((setting, index) => (
          <MenuItem key={index} onClick={() => handleNavigateOrLogout(setting)}>
            <Typography textAlign="center">{setting.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        sx={{ mt: '45px' }}
        id="notifications-menu"
        anchorEl={anchorElNotifications}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseMenus}>
        {notifications && notifications.length === 0 ? (
          <MenuItem>
            <Typography textAlign="center">No notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification, index) => {
            let timeAgo;
            try {
              const createdAt = new Date(notification.createdAt);
              if (isNaN(createdAt)) {
                throw new Error('Invalid date');
              }
              timeAgo = formatDistanceToNow(createdAt, { addSuffix: true });
            } catch (error) {
              timeAgo = 'Unknown time';
            }

            const isExpanded = expandedIndex === index;
            const shouldShowToggle = notification?.subject?.length > 50;

            return (
              <Item key={index}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ width: '100%' }}>
                  <Avatar>
                    {notification.notificationType === 'system' ? (
                      <SettingsIcon />
                    ) : (
                      <NotificationIcon />
                    )}
                  </Avatar>
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ overflow: 'hidden', flexGrow: 1 }}>
                    <Typography noWrap={!isExpanded}>
                      {isExpanded
                        ? notification.subject
                        : `${notification?.subject?.slice(0, 50)}...`}
                    </Typography>
                    {shouldShowToggle && (
                      <Typography
                        variant="body2"
                        color="primary"
                        onClick={() => handleToggleExpand(index)}
                        sx={{ cursor: 'pointer', flexShrink: 0 }}>
                        {isExpanded ? 'Show Less' : 'Show More'}
                      </Typography>
                    )}
                    <Typography variant="body2" color="textSecondary">
                      {timeAgo}
                    </Typography>
                  </Box>
                  {notification.notificationType !== 'system' && (
                    <IconButton edge="end" onClick={(event) => handleOpenMoreMenu(event, index)}>
                      <MoreVertIcon />
                    </IconButton>
                  )}
                </Stack>
                <Menu
                  anchorEl={anchorElMore[index]}
                  open={Boolean(anchorElMore[index])}
                  onClose={handleCloseMenus}>
                  <MenuItem onClick={() => handleHideNotification(notification._id)}>
                    Hide Notification
                  </MenuItem>
                </Menu>
              </Item>
            );
          })
        )}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
