import React, { useEffect } from 'react';
import { useNavigationType, useParams } from 'react-router-dom';
import { getBalances, getDaily, getById, clearAccount } from '../../features/client/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useBanner } from '../../hooks/bannerProvider';
import { useTitle } from '../../hooks/titleProvider';
import { Box } from '@mui/system';
import Overview from '../account/balancesView/components/overview';
import Products from '../account/balancesView/components/products';
// import DailyAccount from '../account/balancesView/components/dailyAccount';
import OrangeCircularProgress from '../ui/controls/circularProgress';

const ClientAccounts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const client = useSelector((state) => state.client.client);
  const account = useSelector((state) => state.client.account);
  const loadingBalances = useSelector((state) => state.client.loading);
  const navigationType = useNavigationType();

  const { setTitle } = useTitle();
  const { setBanner } = useBanner();
  setBanner(null);

  useEffect(() => {
    dispatch(getById(id))
  }, [id, dispatch]);

  useEffect(() => {
    if(client){
      if(client.firstName || client.entityName)
        setTitle((client.firstName ? client.firstName : client.entityName || '') + ' ' + client.lastName || '');
      else if(client.idNumber)
        setTitle(client.idNumber);
      else
        setTitle("Client");

      dispatch(getBalances(client._id));
    }

    return () => {
      if(navigationType === "POP"){
        setTitle('');
        dispatch(clearAccount());
      }
    }
  }, [client, dispatch, setTitle]);

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      {!loadingBalances && account ? (
        <Box width={'100%'} maxWidth={'700px'}>
          {/* <Overview account={account} title={`Fairtree`} /> */}
          <Products account={account} admin={true} />
        </Box>
      ) : (
        <OrangeCircularProgress caption="Fetching your profile summary." />
      )}
    </Box>
  );
};

export default ClientAccounts;
